<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Lista de Locações</h3>
              <h4 v-if="dashboard[this.navitem_at] && [this.dashboard[this.navitem_at]['descricao']] != ''">
                <small>Exibindo {{this.dashboard[this.navitem_at]['descricao']}}</small>
              </h4>
            </div>
            <div class="col-6 text-right">
              
              <base-button 
                v-if="preferenciaapis.erp_id != 0 && preferenciaapis.erp_id !=null"
                type="info" 
                icon size="sm" 
                @click="baixarContrato()"
                >
                  <span class="btn-inner--icon"
                    ><i class="fas fa-code"></i
                  ></span>
                  <span class="btn-inner--text">Baixar do 
                    <span v-if="preferenciaapis.erp_id == 1">Superlogica</span>
                    <span v-else-if="preferenciaapis.erp_id == 2">Imoview</span>
                    <span v-else>ERP</span>
                  </span>
              </base-button>

              <base-button type="primary" icon size="sm" @click="addContrato()">
                <span class="btn-inner--icon"
                  ><i class="fas fa-house-user"></i
                ></span>
                <span class="btn-inner--text">Nova Locação</span>
              </base-button>
            </div>
          </div>

          <div class="container" >
            <template v-for="(alerta, index) in dashboard">
             <base-alert 
                :key="index"
                class="mt-4" 
                dismissible type="warning" 
                icon="fas fa-triangle-exclamation fa-3x" 
                v-if="alerta.is_warning == true && alerta.result_qtd > 0"
              >
                    <H4>ATENÇÃO!</H4>
                    <p>{{alerta.txt_warning}}
                    </p>
                    <base-button class="ml-0" type="secondary"  size="sm" 
                        v-if="navitem_at != index"
                        @click="
                          navitem_at = index;
                          getList();
                        ">
                        Visualizar
                    </base-button >
                    <div v-else>
                      <i>Exibindo Contratos na listagem abaixo</i>
                    </div>
              </base-alert>
            </template>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Pesquisar..."
                clearable
              />
            </div>
          </div>
              <template>
            <ul class="nav nav-tabs nav-fill">
              <li class="nav-item" v-for="(item, index) in dashboard" :key="index">                
                <a
                  v-bind:class="[
                      navitem_at == index ? 'active' : '',
                      'nav-link',
                      (item.result_qtd > 0 || item.style == 'todos') ? '' : 'disabled'
                  ]"
                  v-if="item.is_warning==false || navitem_at == index"
                  href="#"
                  @click="
                    navitem_at = index;
                    getList();
                  "
                  >
                    <badge 
                      class="mr-2" 
                      :type="item.style" 
                      v-if="item.result_qtd > 0"
                      >{{item.result_qtd}}
                    </badge>
                    {{item.title}}</a
                >
              </li>
            </ul>
          </template>
                    

          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="contratos"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              label="Código"
              min-width="90px"
              prop="codigo"
              sortable="custom"
            />
            <el-table-column
              label="Imóvel"
              min-width="200px"
            >
              <template v-slot="{ row }">
                <div>
                  <span
                    v-if="row.imovel"
                    >
                    {{row.imovel.codimovel ? row.imovel.codimovel : '##'  }}: {{ row.imovel.logradouro ? row.imovel.logradouro : '' }} 
                    <span v-if="row.imovel.numero!=0 && row.imovel.numero!='' && row.imovel.numero!=null">
                      nº {{ row.imovel.numero }} 
                    </span>
                    <span v-if="row.imovel.complemento!='' && row.imovel.complemento != null">
                      ({{ row.imovel.complemento }})
                    </span>
                    - {{ row.imovel.bairro }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Seguro"
              min-width="125px"
              prop="seguroistatu.status"
            >
              <template v-slot="{ row }">  
                <div>
                    <div
                      v-if="!['2', '3', '4', '5', '6', '12', '13', '17', '18', '15'].includes(row.seguroistatu.id)"
                      >{{row.seguroistatu.status}}</div
                    >
                    <!--Apólice cadastrada no erp-->
                    <div class="ml-0" v-if="['18'].includes(row.seguroistatu.id)">   
                      <div><b>{{row.seguroistatu.status}}</b></div>
                      <div><small>Ativa até {{formatDate(row.data_renovacao_externa)}}</small></div>
                      <div><small><div><b>*Informações provenientes do ERP</b></div><div><i>*A existencia da apólice e sua vigencia deve ser verificada.</i></div></small></div>
                    </div>
                    <!--Apólice ativa (4) | Sendo analisada seguradora (12) | Enviando porposta (3) | Em periodo de renovação (15)-->
                    <div class="ml-0" v-if="['4', '3', '12', '15'].includes(row.seguroistatu.id)">   
                      <div><b>{{row.seguroistatu.status}}</b></div>
                      <div v-for="(seguro, index) in row.seguroincendio" :key="index">
                          <div v-if="['4', '3', '12', '15'].includes(seguro.seguroistatu_id.toString())">
                            <div v-if="['4','15'].includes(seguro.seguroistatu_id.toString())"><small>
                              <span v-if="!is_vencido(seguro.datainicio)"> De {{ formatDate(seguro.datainicio) }}</span>
                              Até {{ formatDate(seguro.datafim) }}
                            </small></div>
                            <div v-if="seguro.numeroproposta != 0 && seguro.numeroapolice == 0">
                              <small>Proposta: {{ seguro.numeroapolice }}</small>
                            </div>
                            <div v-if="seguro.numeroapolice != 0">
                              <small>Apólice: {{ seguro.numeroapolice }}</small>
                            </div>
                            <div>
                              <span
                                v-if="seguro.numeroproposta != 0 && seguro.propostapdf == 1 && seguro.apolicepdf == 0"
                                class="badge badge-default"
                                :style="{margin: '.1rem', cursor:'pointer'}"
                                content="Baixar Apólice"
                                @click="baixarArquivoProposta(seguro)"
                                >
                                <i class="fas fa-file-pdf"></i>
                                Proposta
                                </span
                              >
                              <span
                                v-if="seguro.numeroapolice != 0 && seguro.apolicepdf == 1"
                                class="badge badge-default"
                                :style="{margin: '.1rem', cursor:'pointer'}"
                                content="Baixar Apólice"
                                @click="baixarArquivo(seguro)"
                                >
                                <i class="fas fa-file-pdf"></i>
                                Apólice
                                </span
                              >
                            </div>
                          </div>
                      </div>
                    </div>
                    <!--FIM Apólice ativa-->

                    <div class="ml-0" v-if="['5', '6', '13', '17', '18'].includes(row.seguroistatu.id) && row.seg_deve_contratar == 1">   
                          <base-button class="ml-0" type="warning"  size="sm"
                           @click="orcarSegIContrato(row)" 
                          >
                            <i class="fas fa-house-fire"></i>Clique para Cotar
                          </base-button>
                    </div>
                    <div class="ml-0" v-if="row.seg_deve_contratar == 0">   
                            Seguro não deve ser Contratado
                    </div>
                    <div class="ml-0" v-if="['2', '15'].includes(row.seguroistatu.id)"> 
                        <!--old code  
                          <base-button class="ml-0" type="warning"  size="sm" 
                            @click="contratarSeguroincendio(row.seguroincendio[0])"
                            v-if="row.seguroincendio.length==1
                            && row.seguroistatu.id == 2 
                            && !is_vencido(row.seguroincendio[0].validade) 
                            && !is_vencido(row.seguroincendio[0].datainicio)"
                          >
                            <i class="fas fa-handshake"></i>Clique para Contratar
                          </base-button>                          
                          <base-button class="ml-0" type="warning"  size="sm" 
                            @click="listaOrcamento(row)"
                            v-if="row.seguroincendio.length==1
                            && ['2','17'].includes(row.seguroistatu.id) 
                            &&(is_vencido(row.seguroincendio[0].datainicio) || is_vencido(row.seguroincendio[0].validade) )"
                          >
                            <i class="fas fa-handshake"></i>Orçamento Expirado
                          </base-button>
                          
                          fim old code-->

                          <!--new code-->
                            <template>
                              <div>
                                <div v-for="(seguro, index) in row.seguroincendio" :key="index">
                                  <!-- Botão para Contratar Seguro -->
                                  <base-button
                                    class="ml-0"
                                    type="warning"
                                    size="sm"
                                    @click="contratarSeguroincendio(seguro)"
                                    v-if="(row.seguroistatu.id == 2 || row.seguroistatu.id == 15)
                                          && seguro.seguroistatu_id.toString() == '2'
                                          && !is_vencido(seguro.validade) 
                                          && !is_vencido(seguro.datainicio)"
                                  >
                                    <i class="fas fa-handshake"></i>Clique para Contratar
                                  </base-button>

                                  <!-- Botão para Orçamento Expirado -->
                                  <base-button
                                    class="ml-0"
                                    type="warning"
                                    size="sm"
                                    @click="listaOrcamento(row)"
                                    v-if="['2','17'].includes(seguro.seguroistatu_id.toString()) 
                                          && (is_vencido(seguro.datainicio) || is_vencido(seguro.validade))"
                                  >
                                    <i class="fas fa-handshake"></i>Orçamento Expirado
                                  </base-button>
                                </div>
                              </div>
                            </template>
                          <!--fim new code-->



                          <!--
                          <base-button class="ml-0" type="warning"  size="sm" 
                            @click="listaOrcamento(row)"
                            v-if="row.seguroincendio.length>1"
                          >
                            <i class="fas fa-handshake"></i>Opções de Contratação
                          </base-button>
                          -->
                    </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column min-width="50px" align="right">
              <template v-slot="{row}">
                  <el-dropdown trigger="click" class="dropdown">
                  <span class="btn btn-sm btn-icon-only">
                    <i class="fas fa-ellipsis-v mt-2"></i>
                  </span>
                      <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" style="cursor:pointer" @click="orcarSegIContrato(row)" 
                            v-if="['5', '6', '13', '17', '10', '18'].includes(row.seguroistatu.id) &&  row.seg_deve_contratar == 1">
                            <i class="fas fa-house-fire"></i>
                            Cotar Seguro Incêndio </a
                          >
                          <a class="dropdown-item" style="cursor:pointer" @click="editContrato(row)"><i class="fas fa-user-edit"></i>Editar contrato</a>
                          <a class="dropdown-item" style="cursor:pointer" @click="deleteContrato(row)"><i class="fas fa-trash"></i>Remover Contrato</a>
                      </el-dropdown-menu>
                  </el-dropdown>
              </template>
              
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Exibindo de {{ total ? from + 1 : 0 }} até {{ to }} de um total de
              {{ total }} registros.

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import Vue from "vue";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      contratos: [],
      preferenciaapis: {
        erp_id: 0,
      },
      navitem_at: 0,
      dashboard:[],
      selectedRows: [],
      sort: "-created_at",

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },
  mounted(){
    this.getPreferenciaapis();
    this.getDashboard();
  },
  methods: {
    
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    formatDate(dateStr) {
      const date = new Date(dateStr + 'T00:00');
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("pt-BR", options);
    },
    
    is_vencido(validade){
      const hoje = new Date();
      hoje.setHours(0, 0, 0, 0);
      validade = new Date(validade + 'T00:00');
      return validade < hoje;
    },
    async AlterarDataInicio(row) {
      if (this.$isDemo == 1 && ["1", "2"].includes(row.id)) {
        this.$notify({
          type: "danger",
          message:
            "Você não esta habilitado para alterar a data",
        });
        return;
      }
      try {
        const { value: date } = await swal.fire({
          title: `Data de inicio de vigencia do seguro`,
          input: "date",
          inputValidator: (value) => {
            if (!value) {
              return "Você precisa selecionar uma data!";
            }

            //verifica se é data futura
            const hoje = new Date();
            hoje.setHours(0, 0, 0, 0);
            var inicio = new Date(value + 'T00:00');
            if (inicio < hoje) {
              return "A data deve ser maior ou igual a hoje!";
            }
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Salvar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          }
        });

        if (date) {
          var seguroincendio = {
            type: "seguroincendios",
            id: row.id,
            datainicio: date,
            contrato: row.contrato,
            clausulaservico: row.clausulaservico,
            metodocalculo: row.metodocalculo,
            relationshipNames: [
              "contrato",
              "clausulaservico",
              "metodocalculo"
            ],
          };
          

          await this.$store.dispatch("seguroincendios/updatedata", seguroincendio);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Dados enviados para nova cotação.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    async Atualizarorcamento(row){
      try {
          var seguroincendio = {
            type: "seguroincendios",
            id: row.id,
            datainicio: null,
            contrato: row.contrato,
            clausulaservico: row.clausulaservico,
            metodocalculo: row.metodocalculo,
            relationshipNames: [
              "contrato",
              "clausulaservico",
              "metodocalculo"
            ],
          };
          
          await this.$store.dispatch("seguroincendios/updatedata", seguroincendio);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Dados enviados para atualizar cotação.",
          });
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    async getList() {
      try {
        //identifica tipo de dados
        var isdata=false;
        var ismoney=false;
        if(this.query){
          //verifica se é uma dta
          const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
          isdata=datePattern.test(this.query);

          //verifica se é uma moeda
          const currencyPattern = /^-?\d+(?:,\d{3})*(?:\.\d{0,2})?$/;
          ismoney=currencyPattern.test(this.query);

        }
        //cria parametros
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          ...(this.navitem_at == 3 ? { sort: 'data_renovacao_externa' } : {}),
          filter: {
            ...((this.dashboard[this.navitem_at] && [this.dashboard[this.navitem_at]['filtro_scope']] != '')
              ? {[this.dashboard[this.navitem_at]['filtro_scope']]: this.dashboard[this.navitem_at]['filtro_valor']}
              : {}),
            ...(this.query ? { codigo: this.query } : {}),
            ...((this.query && isdata) ? { datainicio: this.query } : {}),
            ...((this.query && isdata) ? { valorlocacao: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "imovel,seguroistatu,seguroincendio",
        };
        await this.$store.dispatch("contratos/list", params).then(() => {
          this.contratos = this.$store.getters["contratos/list"];
          this.total = this.$store.getters["contratos/listTotal"];
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    async getPreferenciaapis() {
      try {
        let params = {};

        await this.$store.dispatch("preferencias/list", params).then(() => {
          this.preferenciaapis = this.$store.getters["preferencias/list"];
          this.preferenciaapis=this.preferenciaapis[0];
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    async getDashboard(){
      
      try {
          //Topicos
          await this.$store.dispatch("contratos/dashboard").then(() => {
            this.dashboard=this.$store.getters["contratos/dashboard"];
          });
          

        
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    orcarSegIContrato(row) {
      this.$router.push({
        name: "Add Seguroincendio",
        params: { contrato: row.id },
      });
    },
    
     contratarSeguroincendio(row) {
      this.$router.push({
        name: "Add Seguroicontratar",
        params: { seguroincendio_id: row.id },
      });
    },
    editContrato(row) {
      this.$router.push({
        name: "Edit Contrato",
        params: { id: row.id },
      });
    },
    
    listaOrcamento(row) {
      this.$router.push({
        name: "List Seguroincendios",
        params: { contrato_filter: row.id },
      });
    },
    async deleteContrato(row) {
      try {
        const confirmation = await swal.fire({
          title: `Deseja deletar este Contrato?`,
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Sim, deletar!",
          cancelButtonText: "Não, manter",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          }
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("contratos/destroy", row.id);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Contrato removido com sucesso.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "Este contrato esta associado com outros registros",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    
    async baixarArquivoProposta(row) {
      try {
        // Chama a ação Vuex 'baixarApolice' diretamente
        const resposta = await this.$store.dispatch('seguroincendios/baixarArquivo', ['proposta', row.numeroproposta]);
        // Crie um Blob com o conteúdo do PDF
        const blob = new Blob([resposta], { type: 'application/pdf' });
        // Crie uma URL temporária para o Blob
        const blobUrl = URL.createObjectURL(blob);
        // Abra o PDF em uma nova janela ou guia
        window.open(blobUrl, '_blank');
      } catch (erro) {
        // Lidar com erros, como usuário não autenticado
        //console.error("Erro ao baixar a apólice:", erro);
      }
    },
     async baixarArquivo(row) {
      try {
        // Chama a ação Vuex 'baixarApolice' diretamente
        const resposta = await this.$store.dispatch('seguroincendios/baixarArquivo', ['apolice', row.numeroapolice]);
        // Crie um Blob com o conteúdo do PDF
        const blob = new Blob([resposta], { type: 'application/pdf' });
        // Crie uma URL temporária para o Blob
        const blobUrl = URL.createObjectURL(blob);
        // Abra o PDF em uma nova janela ou guia
        window.open(blobUrl, '_blank');
      } catch (erro) {
        // Lidar com erros, como usuário não autenticado
        //console.error("Erro ao baixar a apólice:", erro);
      }
    },
    async baixarContrato(row) {
      var txtinput="Digite o Código do Contrato";
      var txttitulo="Código do Contrato";
      if(this.preferenciaapis.erp_id==2){
        //imoview
        txtinput="Digite o Código do Imóvel";
        txttitulo="Código do Imóvel";
      } 

      try {
        const confirmation = await swal.fire({
          title: txttitulo,
          type: "question",
          input: "text",
          inputPlaceholder: txtinput,
          inputAttributes: {
            maxlength: "100",
            autocapitalize: "off",
          },
          inputValidator: (value) => {
            if (!value || value.length < 3) {
              return "Você precisa digitar um Código!";
            }
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Baixar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          }
        });

        if (confirmation.isConfirmed === true) {
          var contratocodbaixar = confirmation.value;

          await this.$store.dispatch("contratos/erpbaixar", contratocodbaixar);
          this.$notify({
            type: "success",
            message: "Baixando Contrato, por favor aguarde...",
          });
        }
      } catch (error) {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });

      }
    },
    addContrato() {
      this.$router.push({ name: "Add Contrato" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
